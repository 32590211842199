import React from "react"
import "./gong-neng-mo-kuai.scss"

export default () => (
  <>
    <h1 className="text-center my-5">
      功能模块
    </h1>

    <div className="container d-flex flex-wrap justify-content-start">
      <div className="feature-item">
        <img src="../images/product/5_3.png" className="feature-icon" alt="智能缴费"/>
        <div className="feature-name">智能缴费</div>
      </div>
      <div className="feature-item">
        <img src="../images/product/5_2.png" className="feature-icon" alt="物业报修"/>
        <div className="feature-name">物业报修</div>
      </div>
      <div className="feature-item">
        <img src="../images/product/5_1.png" className="feature-icon" alt="智能通知"/>
        <div className="feature-name">智能通知</div>
      </div>
      <div className="feature-item">
        <img src="../images/product/5_4.png" className="feature-icon" alt="门禁通行"/>
        <div className="feature-name">门禁通行</div>
      </div>
      <div className="feature-item">
        <img src="../images/product/5_5.png" className="feature-icon" alt="智能访客"/>
        <div className="feature-name">智能访客</div>
      </div>
      <div className="feature-item">
        <img src="../images/product/5_8.png" className="feature-icon" alt="企业管理"/>
        <div className="feature-name">企业管理</div>
      </div>
      <div className="feature-item">
        <img src="../images/product/5_9.png" className="feature-icon" alt="租赁管理"/>
        <div className="feature-name">租赁管理</div>
      </div>
      <div className="feature-item">
        <img src="../images/product/5_14.png" className="feature-icon" alt="智能服务单"/>
        <div className="feature-name">智能服务单</div>
      </div>
      <div className="feature-item">
        <img src="../images/product/5_11.png" className="feature-icon" alt="社区运营"/>
        <div className="feature-name">社区运营</div>
      </div>
      <div className="feature-item">
        <img src="../images/product/5_15.png" className="feature-icon" alt="会议室预定"/>
        <div className="feature-name">会议室管理</div>
      </div>
      <div className="feature-item">
        <img src="../images/product/5_10.png" className="feature-icon" alt="移动报表"/>
        <div className="feature-name">移动报表</div>
      </div>
      <div className="feature-item">
        <img src="../images/product/5_13.png" className="feature-icon" alt="数据大屏"/>
        <div className="feature-name">数据大屏</div>
      </div>
      <div className="feature-item">
        <img src="../images/product/5_7.png" className="feature-icon" alt="设备管理"/>
        <div className="feature-name">设备管理</div>
      </div>
      <div className="feature-item">
        <img src="../images/product/5_6.png" className="feature-icon" alt="智能巡更"/>
        <div className="feature-name">智能巡更</div>
      </div>
      <div className="feature-item">
        <img src="../images/product/5_12.png" className="feature-icon" alt="停车管理"/>
        <div className="feature-name">停车管理</div>
      </div>
    </div>
  </>
);
