import React from "react";
import Layout from "../components/layout"
import GongNengMoKuai from "../components/gong-neng-mo-kuai"
import "./product.scss"

export default () => (
  <Layout pageId={"/product"}>
      {/*<div className="container-fluid">*/}
      {/*    <div className="row  align-items-center">*/}
      {/*        <div className="col-lg-7 col-7">*/}
      {/*            <img src="../images/product/2.png" className="func-module-icon"/>*/}
      {/*        </div>*/}
      {/*        <div className="col-lg-5 col-4">*/}
      {/*            <div className="horizontal—line"/>*/}
      {/*            <div className="product_title_1">*/}
      {/*                用户端APP*/}
      {/*            </div>*/}
      {/*            <div className="product_title_2">*/}
      {/*                便捷用户*/}
      {/*            </div>*/}
      {/*        </div>*/}
      {/*    </div>*/}
      {/*</div>*/}
      {/*<div className="container-fluid">*/}
      {/*    <div className="row  justify-content-center align-items-center">*/}
      {/*        <div className="col-lg-2 col-4">*/}
      {/*            <div className="horizontal—line"/>*/}
      {/*            <div className="product_title_1">*/}
      {/*                管理端APP*/}
      {/*            </div>*/}
      {/*            <div className="product_title_2">*/}
      {/*                移动高效管理*/}
      {/*            </div>*/}
      {/*        </div>*/}
      {/*        <div className="col-7 col-lg-7">*/}
      {/*            <img src="../images/product/3.png" className="func-module-icon"/>*/}
      {/*        </div>*/}
      {/*    </div>*/}
      {/*</div>*/}
      {/*<div className="container-fluid">*/}
      {/*    <div className="row  justify-content-center align-items-center">*/}
      {/*        <div className="col-7 col-lg-7">*/}
      {/*            <img src="../images/product/1.png" className="func-module-icon"/>*/}
      {/*        </div>*/}
      {/*        <div className="col-4 col-lg-4">*/}
      {/*            <div className="horizontal—line"/>*/}
      {/*            <div className="product_title_1">*/}
      {/*                微信小程序*/}
      {/*            </div>*/}
      {/*            <div className="product_title_2">*/}
      {/*                方便快捷*/}
      {/*            </div>*/}
      {/*        </div>*/}

      {/*    </div>*/}
      {/*</div>*/}

    <h1 className="text-center my-5">
      管理平台
    </h1>
    <div className="container d-flex flex-wrap justify-content-around">
      <div className="func-module-item">
        <img src="../images/product/4_3.png" className="func-module-icon" alt=""/>
        <div className="func-module-name">企业管理</div>
      </div>
      <div className="func-module-item">
        <img src="../images/product/4_1.png" className="func-module-icon" alt=""/>
        <div className="func-module-name">资产管理</div>
      </div>
      <div className="func-module-item">
        <img src="../images/product/4_4.png" className="func-module-icon" alt=""/>
        <div className="func-module-name">空间管理</div>
      </div>
      <div className="func-module-item">
        <img src="../images/product/4_5.png" className="func-module-icon" alt=""/>
        <div className="func-module-name">物业管理</div>
      </div>
      <div className="func-module-item">
        <img src="../images/product/4_2.png" className="func-module-icon" alt=""/>
        <div className="func-module-name">运营管理</div>
      </div>
      <div className="func-module-item">
        <img src="../images/product/4_6.png" className="func-module-icon" alt=""/>
        <div className="func-module-name">统计分析</div>
      </div>
    </div>

    <GongNengMoKuai/>

    <h1 className="text-center my-5">
      硬件产品
    </h1>
    <div className="container my-5">
      <div className="row justify-content-center">
        <div className="col-lg-4 col-6 hardware-product">
          <img src="../images/product/6_1.png" className="img-fluid" alt="电子锁"/>
        </div>
        <div className="col-lg-4 col-6 hardware-product">
          <img src="../images/product/6_2.png" className="img-fluid" alt=""/>
        </div>
        <div className="col-lg-4 col-6 hardware-product">
          <img src="../images/product/6_3.png" className="img-fluid" alt="人脸识别一体机"/>
        </div>
        <div className="col-lg-4 col-6 hardware-product">
          <img src="../images/product/6_4.png" className="img-fluid" alt="梯控"/>
        </div>
        <div className="col-lg-4 col-6 hardware-product">
          <img src="../images/product/6_5.png" className="img-fluid" alt=""/>
        </div>
        <div className="col-lg-4 col-6 hardware-product">
          <img src="../images/product/6_6.png" className="img-fluid" alt="人行道闸"/>
        </div>
        <div className="col-lg-4 col-6 hardware-product">
          <img src="../images/product/6_7.png" className="img-fluid" alt="车道闸"/>
        </div>
        <div className="col-lg-4 col-6 hardware-product">
          <img src="../images/product/6_8.png" className="img-fluid" alt=""/>
        </div>
        <div className="col-lg-4 col-6 hardware-product">
          <img src="../images/product/6_9.png" className="img-fluid" alt=""/>
        </div>
        <div className="col-lg-4 col-6 hardware-product">
          <img src="../images/product/6_10.png" className="img-fluid" alt=""/>
        </div>
        <div className="col-lg-4 col-6 hardware-product">
          <img src="../images/product/6_11.png" className="img-fluid" alt=""/>
        </div>
        <div className="col-lg-4 col-6 hardware-product">
          <img src="../images/product/6_12.png" className="img-fluid" alt=""/>
        </div>
      </div>
    </div>
  </Layout>
);
